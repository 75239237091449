///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* BG */

	#bg {
		@include vendor('transform', 'scale(1.0)');
		-webkit-backface-visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 1;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:before {
			background-color: white;
		}

		body.is-article-visible & {
			&:after {
				@include vendor('transform', 'scale(1.0825)');
				@include vendor('filter', 'blur(0.2rem)');
			}
		}

		body.is-preload & {
			&:before {
				background-color: _palette(bg-alt);
			}
		}
	}