///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

	.icon {
		border-bottom: none;
		position: relative;

		> .label {
			display: none;
		}
	}

	.logo-icon {
		background: url(../../images/logo-icon.png) no-repeat center center;
		background-size: contain;
		width: 86px;
		height: 86px;
		display: block;
		border-radius: 50%;
	}